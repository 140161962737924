<template>
  <div class="newsletter">
    <h1 class="title">Newsletter</h1>
    <div class="articles">
      <div v-for="article of articles" :key="article.id" class="article">
        <h2 class="article-title">{{ article.title }}</h2>
        <div v-show="isConnected" class="edit-del-icons"><v-icon name="pen" class="pen-icon" @click="editArticle(article)"></v-icon><v-icon name="trash" class="trash-icon" @click="deleteArticle(article)"></v-icon></div>
        <div class="article-content" v-html="article.content"></div>
        <div class="article-timestamp">{{ changeDateFormat(article.createdAt) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  props: {
    isConnected: { type: Boolean },
    editedArticle: { type: Object }
  },
  data() {
    return {
      articles: { type: Object }
    }
  },
  async mounted() {
    this.articles = await axios.get('https://jrg-consulting.fr/api/newsletter')
    this.articles = this.articles.data.result
  },
  methods: {
    editArticle(articleId) {
      this.$emit('edit-article', articleId)
    },
    deleteArticle(article) {
      if (confirm('Voulez-vous vraiment supprimer cet article ?')) {
        this.articles.pop(article.id)
        this.$emit('delete-article', article.id)
      }
    },
    changeDateFormat(timestamp) {
      const year = timestamp.slice(0, 4)
      const month = timestamp.slice(5, 7)
      const day = timestamp.slice(8, 10)
      return day + '/' + month + '/' + year
    }
  }
}
</script>

<style lang="scss" scoped>
.articles {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.article {
  border: 1px solid #01A3A4;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}

.article-title {
  font-weight: 500;
  font-size: 22px;
  position: absolute;
  top: -15px;
  left: -1px;
  padding-right: 10px;
  background-color: #fafafa;
}

.edit-del-icons {
  position: absolute;
  right: 5px;
  top: -10px;
  background-color: #fafafa;
  padding-left: 10px;
  padding-right: 10px;
}

.pen-icon {
  cursor: pointer;
  margin-right: 10px;
}

.trash-icon {
  margin-left: 10px;
  cursor: pointer;
}

.article-content {
  margin: 20px 10px 15px 10px;
  text-align: justify;
  & ::v-deep ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
  & ::v-deep ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  & ::v-deep iframe {
    width: 500px;
    height: 300px;
    display: flex;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
  & ::v-deep h1 {
    font-size: 22px;
  }
  & ::v-deep h2 {
    font-size: 21px;
  }
  & ::v-deep h3 {
    font-size: 20px;
  }
  & ::v-deep h4 {
    font-size: 19px;
  }
  & ::v-deep h5 {
    font-size: 18px;
  }
  & ::v-deep h6 {
    font-size: 17px;
  }
  & ::v-deep .ql-size-small {
    font-size: 14px;
  }
  & ::v-deep .ql-size-large {
    font-size: 18px;
  }
  & ::v-deep .ql-size-huge {
    font-size: 20px;
  }
  & ::v-deep blockquote {
    border-left: 4px solid #01A3A4;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 10px;
  }
}

.article-content >>> ol {
  color: #01A3A4;
}

.article-timestamp {
  font-weight: 300;
  font-size: 14px;
  position: absolute;
  right: -5px;
  bottom: -9px;
  padding-left: 10px;
  padding-top: 9px;
  background-color: #fafafa;
}
</style>